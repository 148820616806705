/*
Extend this component as needed, currently used in CustomerPickupModal.js
*/

import React, { HTMLAttributes } from "react";
import { FaSort } from "@react-icons/all-files/fa/FaSort";
import styled from "styled-components";

import { Flex, Box } from "../";

type Option = {
    key?: string;
    text: string;
    value: string;
    disabled?: boolean;
    dataObject?: any;
};

interface Props {
    width: string;
    height: string;
    customIcon: any;
}

interface SelectProps extends Props, HTMLAttributes<HTMLSelectElement> {
    options: Option[];
    value: string;
    isDisabled: boolean;
}

const SelectContainer = styled.div<Partial<Props>>`
    display: flex;
    width: ${props => props.width};
    height: ${props => props.height};
`;

const Select = styled("select").attrs<SelectProps>(({ isDisabled }) => ({
    disabled: isDisabled
}))<Partial<SelectProps>>`
    width: 100%;
    height: 3rem;
    background-color: white;
    color: ${props => (props.value === "" ? "rgba(191, 191, 191, 0.87)" : "rgba(0, 0, 0, 0.87)")};
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-family: "Lato", sans-serif;
    font-size: 1.125rem;
    ${({ isDisabled }) => {
        if (isDisabled) {
            return `opacity: 0.4;
                    cursor: not-allowed;
                `;
        }
    }}}
    &:focus,
    &:active {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    & > option[value=""][disabled] {
        display: none;
    }
    & > option {
        color: rgba(0, 0, 0, 0.87);
    }
`;

const Dropdown: React.FC<Partial<SelectProps & { placeholder: string }>> = props => {
    const { options, placeholder, width, height, customIcon } = props;
    return (
        <SelectContainer width={width} height={height}>
            <Select {...props}>
                <option value="" disabled>
                    {placeholder}
                </option>
                {options &&
                    options.map((opt: Option) => {
                        return (
                            <option
                                key={opt.key || opt.text}
                                value={opt.value}
                                disabled={opt.disabled}
                                data-container={opt.dataObject}
                            >
                                {opt.text}
                            </option>
                        );
                    })}
            </Select>
            <Flex pointerEvents="none" right="1.5rem" position="relative" alignSelf="center" height="auto">
                <Box color="rgba(0, 0, 0, 0.87)" as={!!customIcon ? customIcon : FaSort} />
            </Flex>
        </SelectContainer>
    );
};

export default Dropdown;
